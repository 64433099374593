<template>
  <page>
    <div class="editPage">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="phoneTip">请填写您的真实姓名，以便加入公司</div>
      <input type="text" v-model='name' class="nameInput" placeholder="请输入姓名">

      <button class="btn btn_radius theme_bg margin_auto editBtn" @click='changeName'>确定</button>
    </div>
  </page>
</template>

<script>
import loginApi from '@/api/user'

export default {
  data: ()=> ({
    name: ''
  }),
  methods: {
    changeName() {
      if (this.name) {
        loginApi.modifyInfo({
          userName: this.name
        }).then(res => {
          if (res.data) {
            this.$toast(res.data.msg)
            const userInfo = this.$global.getItem('userInfo', true) || this.$global.getItem('userInfo')
            userInfo.userName = this.name
            this.$global.setItem('userInfo', userInfo)
            this.$global.setItem('userInfo', userInfo, true)
            setTimeout(() => {
              this.$router.back()
            }, 1000)
          }
        })
      } else {
        this.$toast('请输入您的真实姓名')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-bottom: 15px;
}
.editPage {
  padding: 20px;
}
.nameInput {
  padding-bottom: 5px;
  margin-top: 10px;
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.editBtn {
  width: 80%;
  margin-top: 154px;
}
</style>